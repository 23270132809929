<template>
	<main-container :showHeader="false">
		<el-card>
			<div class="d-flex flex-row flex-wrap">
				<form-item label="项目:" style="width:200px;">
					<el-select v-model="queryForm.project" placeholder="请选择" size="mini" clearable>
						<el-option label="Clothe" value="Clothe"></el-option>
						<el-option label="3C" value="3C"></el-option>
					</el-select>
				</form-item>
				<form-item label="domain:">
					<el-input v-model="queryForm.domain" size="mini" clearable></el-input>
				</form-item>
				<form-item label="建站系统:" style="width:200px;">
					<el-select v-model="queryForm.sys" placeholder="请选择" size="mini" clearable>
						<el-option label="自建" value="SELF"></el-option>
						<el-option label="Shopify" value="Shopify"></el-option>
						<el-option label="Shoplazza" value="Shoplazza"></el-option>
					</el-select>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="visible.edit=true">添加配置</el-button>
				</form-item>
			</div>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData">
				<el-table-column label="项目" prop="project" min-width="75">
				</el-table-column>
				<el-table-column label="domain" min-width="80">
					<template slot-scope="scope">
						<text-truncate :text="scope.row.domain"></text-truncate>
					</template>
				</el-table-column>
				<el-table-column label="站点缩写" prop="site" min-width="100">
				</el-table-column>
				<el-table-column label="GA ID" min-width="100">
					<template slot-scope="scope">
						<text-truncate :text="scope.row.gaId"></text-truncate>
					</template>
				</el-table-column>
				<el-table-column label="建站系统" min-width="80">
					<template slot-scope="scope">
						<text-truncate :text="scope.row.sys"></text-truncate>
					</template>
				</el-table-column>
				<el-table-column label="店铺名称" min-width="80">
					<template slot-scope="scope">
						<text-truncate :text="scope.row.name"></text-truncate>
					</template>
				</el-table-column>
				<el-table-column label="Token" min-width="80">
					<template slot-scope="scope">
						<text-truncate :text="scope.row.token"></text-truncate>
					</template>
				</el-table-column>
				<el-table-column label="权限状态" prop="status" min-width="80">
				</el-table-column>
				<el-table-column label="操作" min-width="100">
					<template slot-scope="scope">
						<el-button type="text" size="mini" style="color:black;" @click="editRow(scope.row)">编辑</el-button>
						<el-button type="text" size="mini" class="text-danger" @click="delRow(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
		<el-dialog :title="form.id ? '编辑配置': '添加配置'" :visible.sync="visible.edit" :before-close="handleClose">
			<el-form ref="site-form" :model="form" label-width="80px" hide-required-asterisk :rules="rules">
				<el-form-item label="项目" prop="project">
					<el-select v-model="form.project" placeholder="请选择" class="w-100">
						<el-option label="Clothe" value="Clothe"></el-option>
						<el-option label="3C" value="3C"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="domain" prop="domain">
					<el-input v-model.trim="form.domain"></el-input>
				</el-form-item>
				<el-form-item label="站点缩写" prop="site">
					<el-input v-model.trim="form.site" @input="form.site=form.site.toUpperCase()"></el-input>
				</el-form-item>
				<el-form-item label="GA ID" prop="gaId">
					<el-input v-model.trim="form.gaId"></el-input>
				</el-form-item>
				<el-form-item label="建站系统" prop="sys">
					<el-select v-model="form.sys" placeholder="请选择" class="w-100">
						<el-option label="自建" value="SELF"></el-option>
						<el-option label="Shopify" value="Shopify"></el-option>
						<el-option label="Shoplazza" value="Shoplazza"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="店铺名称">
					<el-input v-model.trim="form.name"></el-input>
				</el-form-item>
				<el-form-item label="Token">
					<el-input v-model.trim="form.token"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.edit=false">取 消</el-button>
				<el-button type="primary" @click="saveOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import {
		mapActions,
		mapState
	} from 'vuex'
	const InitialForm = {
		project: '',
		domain: '',
		site: '',
		gaId: '',
		sys: '',
		name: '',
		token: ''
	}
	export default {
		mixins: [page, common],
		inject: ['adminLayout'],
		components: {
		},
		data() {
			return {
				queryForm: {
					project: '',
					domain: '',
					sys:''
				},
				q: {},
				tableData: [],
				form: {...InitialForm},
				visible: {
					edit: false
				},
				rules: {
					project: [{
						required: true,
						message: '项目不能为空'
					}],
					domain: [{
						required: true,
						message: 'domain不能为空'
					}],
					site: [{
						required: true,
						message: '站点缩写不能为空'
					}],
					gaId: [{
						required: true,
						message: 'GA ID不能为空'
					}],
					sys: [{
						required: true,
						message: '建站系统不能为空'
					}]
				}
			}
		},
		watch: {
			'visible.edit'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs['site-form'].clearValidate()
					})
				} else {
					this.form = {...InitialForm}
				}
			}
		},
		methods: {
			...mapActions([
				'fetchSites',
				'fetchSite',
				'delSite',
				'updateSite',
				'createSite'
			]),
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.fetchSites({
					params: {
						...this.page,
						...this.q
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			// Button Events
			searchEvent() {
				this.q = {...this.queryForm}
				this.page.current = 1
				this.getList()
			},
			saveOrUpdate() {
				this.$refs['site-form'].validate(v => {
					if (v) {
						console.log(this.form.id)
						const isAdd = this.form.id === undefined
						const api = isAdd ? this.createSite : this.updateSite
						const body = isAdd ? {...this.form} : {id: this.form.id, body: {...this.form}}
						this.adminLayout.showLoading(true)
						api(body)
						.then(res => {
							this.adminLayout.hideLoading()
							this.$showSuccess(isAdd ? '已添加' : '已更新')
							this.visible.edit = false
							if (isAdd) {
								this.getList()
							} else {
								const row = this.tableData.find(v => v.id === res.id)
								if (row) {
									Object.keys(row).forEach(key => {
										row[key] = res[key]
									})
								}
							}
						})
						.catch(err => {
							this.$showErrMsg(err)
							this.adminLayout.hideLoading()
						})
					}
				})
			},
			editRow(row) {
				const {id} = row
				this.adminLayout.showLoading()
				this.fetchSite(id)
				.then(res => {
					this.adminLayout.hideLoading()
					this.form = {
						...InitialForm,
						...res
					}
					this.visible.edit = true
				})
				.catch(err => {
					this.adminLayout.hideLoading()
					this.$showErrMsg(err)
				})
			},
			delRow(row) {
				this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const {id} = row
					this.adminLayout.showLoading()
					this.delSite(id)
					.then(res => {
						this.adminLayout.hideLoading()
						this.getList(this.page.current)
					})
					.catch(err => {
						this.adminLayout.hideLoading()
						this.$showErrMsg(err)
					})
				})
			}
		}
	}
</script>

<style lang="scss">

</style>
